<!-- Name -->
<script lang="ts">
export default {
  name: 'app-popup'
};
</script>

<script setup lang="ts">

import { usePopupsStore } from '~/store/popups';
const route= useRoute();

const popupsStore = usePopupsStore();

const {popups} = storeToRefs(popupsStore);
const { brand, type, status,url } = toRefs(popupsStore.show);

const { startTimer, resetTimer, stopTimer, addEventListeners, removeEventListeners,isInactive } = useInactiveDetector(popupsStore.getTimeOut(route.params.marca));

const popup=ref();

onMounted(() => {
  startTimer();
  addEventListeners();
});

onBeforeUnmount(() => {
  removeEventListeners();
  stopTimer();
});

watch(isInactive, (newValue) => {
  if (newValue) {
    popupsStore.closePopup();
    popupsStore.showPopup(route.params.marca ?? '','timeout');
  }
});

watch(status,() => {
    if (process.client) {
        if (status.value) {
            document.body.classList.add('modal-open');
        } else {
            document.body.classList.remove('modal-open');
        }
    }
});




useDetectOutsideClick(popup, () => {       
    //Comprueba que no este visible el popup de cookiebot
      popupsStore.closePopup();
});




const cta = computed(()=>{
  return (url.value['cta'] && url.value['cta'] !=='') ? url.value['cta']: '';
});

const clickPopup = async () => {
  if(cta.value !== ''){
    await navigateTo(cta.value, {
    external: true,
    open: {
      target:'_blank'
    }
  });
  } 
};

</script>

<template lang="pug">
Transition
  .overlay-popup(v-if="status" ref="popup")
      .overlay-popup__content(@click.prevent="clickPopup()" :class="{'pointer':cta!==''}")
          ClientOnly
              .d-block.d-lg-none()
                  img.img-fluid.mobile(:src="url['mobile']")
                  span.icon-times(@click.prevent="popupsStore.closePopup()")
              .d-none.d-lg-block
                  img.img-fluid.desktop(:src="url['desktop']")
                  span.icon-times(@click.prevent="popupsStore.closePopup()")
                

</template>

<style lang="scss" scoped>
@import 'index';
</style>
